.btn-default {
	display: inline-block;
	padding: 14px 60px;
	color: $main-gold;
	font-size: 11px;
	line-height: 20px;
	background: $white;
	border-radius: 5px;
	text-transform: uppercase;
	transition: all .35s ease;
	margin-top: 24px;

	&:hover {
		background: $main-gold;
		color: $white;
	}
}

button.btn-default {
	padding: 14px 85px;
	background-color: $main-gold;
	color: $white;
	border: none;
	margin: 0;
	cursor: pointer;
	border: 1px $white solid;

	&:hover {
		background-color: $white;
		color: $main-gold;
		border-color: $main-gold;
	}
}