.legals {
	width: 100%;
	margin: 40px 0;

	@include breakpoint(tablet) {
		min-height: 50vh;
	}
	
	p {
		margin: 0 10px 20px 10px;
	}
}
