.main-header {
	position: absolute;
	z-index: 11;
	width: 100%;

	.contact & {
		position: relative;
		background-color: $light-grey;
		border-bottom: 1px $main-gold solid;
		padding-bottom: 10px;

		@include breakpoint(phablet) {
			padding-bottom: 30px;
		}

		.wrap {
			display: flex;
			justify-content: space-between;
			padding: 20px;

			@include breakpoint(laptop) {
				padding: 40px 50px 0;
			}

		}

		.logo svg * {
			fill: $main-blue!important;
			opacity: 1;
		}

		.menu {
			z-index: 12;
			position: relative;
			width: 50px;
			height: 50px;
			cursor: pointer;
			margin: -20px -20px 0 0;
			
			@include breakpoint(tablet) {
				margin: 0;
			}

			span {
				position: absolute;
				top: 12px;
				left: 50%;
				cursor: pointer;
				width: 30px;
				height: 2px;
				background-color: $main-gold;

				@include breakpoint(tablet) {
					width: 60px;
				}
	
				&.top {
					transform: translateY(10px) translateX(-50%) rotate(45deg);
				}
				&.bottom {
					transform: translateY(10px) translateX(-50%) rotate(-45deg);
				}
			}

		}
	}
	
	.wrap {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.logo {
		display: inline-block;

		svg {
			width: 100%;

			@include breakpoint(phablet) {
				width: 310px;
			}

			* {
				fill: #fff;

				@include breakpoint(phargemobile) {
					opacity: 0;

					&.vm-iso {
						opacity: 1;
					}
				}
			}
		}
	}

	@include breakpoint(laptop) {
		.wrap {
			padding: 40px 50px 0;
		}
	}
}