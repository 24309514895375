// FONTS
$font-base:			'Open Sans', sans-serif;
$font-title:		'adobe-garamond-pro',sans-serif;

// COLORS
$main-gray:			#949494;
$main-blue:			#002a4e;
$main-gold:			#b38b06;
$sec-gold:			#de9829;
$light-grey:		#fafafa;
$grey:				#666;
$white:				#fff;

// BREAKPOINTS

@mixin breakpoint($point) {
	@if $point == desktop {
		@media (min-width: 1440px) { @content ; }
	}
	@else if $point == laptop {
		@media (min-width: 1200px) { @content ; }
	}
	@else if $point == tablet {
		@media (min-width: 1024px) { @content ; }
	}
	@else if $point == phablet {
		@media (min-width: 768px)  { @content ; }
	}
	@else if $point == largemobile {
		@media (min-width: 361px)  { @content ; }
	}
	@else if $point == phargemobile {
		@media (max-width: 768px) and (orientation: landscape) { @content ; }
	}
}
html,
body { -webkit-font-smoothing: antialiased; }

html { box-sizing: border-box; }
*, *:before, *:after { box-sizing: inherit; }

body * {
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
	color: $main-gray;
	font: normal 16px/26px $font-base;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
	font-weight: normal;
}

img { max-width: 100%; }
p { margin: 0; }
a { text-decoration: none;}

.wrap {
	// max-width: 1400px;
	padding: 0 15px;
	margin: 0 auto;

	@media screen and (min-width: 1024px) {
		padding: 0 50px;
	}
}
@import 'https://fonts.googleapis.com/css?family=Open+Sans';
.footer {
	padding: 25px 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	    align-items: center;
	-ms-flex-pack: distribute;
	    justify-content: space-around;
	-ms-flex-direction: column;
	    flex-direction: column;
	position: relative;

	.contact & {
		border-top: 1px $main-gray solid;
	}

	&> * {		
		-ms-flex: 1;		
		    flex: 1;
		text-align: center;
	}

	&-col1 {
		height: 32px;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: center;
		    justify-content: center;
	}

	&-col2 {
		margin: 40px 0;
	}

	@include breakpoint(tablet) {
		-ms-flex-direction: row;
		    flex-direction: row;

		&-col2 {
			margin: 0;
		}


	}

	a {
		color: $main-blue;
		font-size: 16px;
		line-height: 32px;
		font-family: $font-title;

		&:hover {
			text-decoration: underline;
		}
	}

	&-logo-penn {
		width: 120px;
		margin-right: 10px;
	}
	&-tel {
		width: 13px;
		margin-left: 10px;
		margin-right: 10px;
	}

	&-logo-vmcgc {
		width: 113px;
	}
}
.main-header {
	position: absolute;
	z-index: 11;
	width: 100%;

	.contact & {
		position: relative;
		background-color: $light-grey;
		border-bottom: 1px $main-gold solid;
		padding-bottom: 10px;

		@include breakpoint(phablet) {
			padding-bottom: 30px;
		}

		.wrap {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-pack: justify;
			    justify-content: space-between;
			padding: 20px;

			@include breakpoint(laptop) {
				padding: 40px 50px 0;
			}

		}

		.logo svg * {
			fill: $main-blue!important;
			opacity: 1;
		}

		.menu {
			z-index: 12;
			position: relative;
			width: 50px;
			height: 50px;
			cursor: pointer;
			margin: -20px -20px 0 0;
			
			@include breakpoint(tablet) {
				margin: 0;
			}

			span {
				position: absolute;
				top: 12px;
				left: 50%;
				cursor: pointer;
				width: 30px;
				height: 2px;
				background-color: $main-gold;

				@include breakpoint(tablet) {
					width: 60px;
				}
	
				&.top {
					transform: translateY(10px) translateX(-50%) rotate(45deg);
				}
				&.bottom {
					transform: translateY(10px) translateX(-50%) rotate(-45deg);
				}
			}

		}
	}
	
	.wrap {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.logo {
		display: inline-block;

		svg {
			width: 100%;

			@include breakpoint(phablet) {
				width: 310px;
			}

			* {
				fill: #fff;

				@include breakpoint(phargemobile) {
					opacity: 0;

					&.vm-iso {
						opacity: 1;
					}
				}
			}
		}
	}

	@include breakpoint(laptop) {
		.wrap {
			padding: 40px 50px 0;
		}
	}
}
.btn-default {
	display: inline-block;
	padding: 14px 60px;
	color: $main-gold;
	font-size: 11px;
	line-height: 20px;
	background: $white;
	border-radius: 5px;
	text-transform: uppercase;
	transition: all .35s ease;
	margin-top: 24px;

	&:hover {
		background: $main-gold;
		color: $white;
	}
}

button.btn-default {
	padding: 14px 85px;
	background-color: $main-gold;
	color: $white;
	border: none;
	margin: 0;
	cursor: pointer;
	border: 1px $white solid;

	&:hover {
		background-color: $white;
		color: $main-gold;
		border-color: $main-gold;
	}
}
.contact {
	width: 100%;

	@include breakpoint(tablet) {
		margin-bottom: 20px;
		min-height: 50vh;
	}
	
	&-form {
		padding-top: 20px;

		@include breakpoint(phablet) {
			padding-top: 40px;
		}

		.row {
			
			@include breakpoint(phablet) {
				display: -ms-flexbox;
				display: flex;
			}

			.form-group {
				-ms-flex: 1;
				    flex: 1;
				margin: 0 10px;
				position: relative;
				margin-bottom: 30px;
				text-rendering: optimizeLegibility;
				font-smooth: always;

				&.val-error {
					label {
						color: red;
					}
					input {
						border-bottom-color: red!important; 
					}
				}

				&.focus label[for], &.active label[for] {
				    top: 0;
				    font-size: 11px;
				}
				
				label[for] {
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					padding: 0 10px;
					font-family: $font-base;
					font-size: 16px;
					pointer-events: none;
					transition: all .2s ease;

				}
				input {
					width: 100%;
					height: 50px;
					padding: 0 10px;
					border: 1px solid $main-gray;
					border-width: 0 0 1px;
					background-color: transparent;
					border-radius: 0;
					
					&:focus {
						border-color: $main-gold;
						outline: none;

						&+ label { 
							font-size: 12px;
							top: -5px;
						}
					}

					&[type=radio] {
						width: auto;
						height: 16px;

						&+ label {
							margin-left: 10px;
							position: static;
							transform: none;
							line-height: 50px;
							font-size: 16px;
							top: 50%;
						}
					}

					&[name=notes] {

						@include breakpoint(tablet) {
							width: 80%
						}
					}
				}

				&-col {
					// width: 30%;
					display: -ms-flexbox;
					display: flex;
					-ms-flex-align: center;
					    align-items: center;					

					&:last-child {
						padding-left: 16px;
						width: auto;
					}

					p {
						font-size: 16px;
						padding: 0 10px;
						text-align: left;

						@include breakpoint(phablet) {
							padding-left: 20px;
						}

						small {
							font-size: 12px;
							display: block;
						}
					}
				}

				&.row {
					display: -ms-flexbox;
					display: flex;

					&.interest {
						-ms-flex-direction: column;
						    flex-direction: column;

						@include breakpoint(phablet) {
							-ms-flex-direction: row;
							    flex-direction: row;
							margin: 30px 0;
						}

						.form-group-col {
							width: 100%;

							@include breakpoint(phablet) {
								-ms-flex: 1 1 20%;
								    flex: 1 1 20%;
								width: inherit;
							}

							&:last-child {
								-ms-flex: 1 1 auto;
								    flex: 1 1 auto;
								-ms-flex-wrap: wrap;
								    flex-wrap: wrap;
								padding-left: 10px;
								-ms-flex-direction: column;
								    flex-direction: column;

								@include breakpoint(phablet) {
									padding: 0;
									-ms-flex-direction: row;
									    flex-direction: row;
									-ms-flex: 1 1 80%;
									    flex: 1 1 80%; 
								}

								span {
									display: -ms-flexbox;
									display: flex;
									-ms-flex-direction: column;
									    flex-direction: column;
									
									label {
										min-width: 100px;
										padding: 10px 30px 0 0;

										@include breakpoint(phablet) {
											padding: 0 30px 0 0;
										}

										input {
											margin-right: 10px;
										}
									}
								}
							}

							input {
								width: auto;
								height: auto;
							}
						}
					}
				}
			}

			&:last-child {

				.form-group:last-child {
					text-align: center;

					@include breakpoint(phablet) {
						text-align: right;
					}
				}
			}
		}
	}

	&-form-title {
		h2 {
			font: 34px $font-title;
			color: $main-gold;
			text-align: center;

			@include breakpoint(phablet) {
				text-align: left;
			}
		}

		margin: 0 10px 20px 10px;

		@include breakpoint(phablet) {
			margin-bottom: 40px;
		}
	}

	&-thank {
		display: -ms-flexbox;
		display: flex;
		padding: 40px 0;
		
		@include breakpoint(tablet) {
			min-height: 50vh;
		}

		aside {
			margin: auto;
			text-align: center;

			h2 {
				font-family: $font-title;
				color: $main-gold;

				@include breakpoint(tablet) {
					font-size: 36px;
				}
			}
			p {	
				line-height: 50px;
				margin-bottom: 46px;
			}
		}
	}

	.is-hidden, .is-hidden.loader {
	    display: none;
	    opacity: 0;
	    visibility: hidden;
	}

	.loader {
	    min-height: 50vh;
    	display: -ms-flexbox;
    	display: flex;

    	svg {
    		margin: auto;
    	}
	}
}

.hero {
	width: 100%;
	height: 100vh;
	overflow: hidden;

	.slides {
		position: relative;
		height: 100vh;

		.tagline {
			width: 90%;
			text-align: center;
			position: absolute;
			z-index: 10;
			right: 50%;
			bottom: 70px;
			transform: translateX(50%);

			@include breakpoint(tablet) {
				max-width: 450px;
				text-align: right;
				right: 110px;
				bottom: 150px;
				transform: inherit;
			}

			@include breakpoint(phargemobile) {
				width: 60%;
				text-align: right;
				right: 30px;
				transform: inherit;
			}
			
			h2 {
				font: 34px/34px $font-title;
				color: $white;
				padding-bottom: 24px;
				margin-bottom: 20px;
				position: relative;

				&:after {
					content: '';
					width: 120px;
					border-bottom: 1px $sec-gold solid;
					position: absolute;
					bottom: 0px;
					right: 50%;
					transform: translateX(50%);

					@include breakpoint(tablet) {
	 					right: 0;
	 					transform: inherit;
	 				}
	 				@include breakpoint(phargemobile) {
	 					right: 0;
	 					transform: inherit;
	 				}
				}
			}
			p {
				display: none;
				font-size: 14px;
				color: $white;
				text-rendering: optimizeLegibility;
				font-smooth: always;

				@include breakpoint(largemobile) {
					display: block;
				}
				@include breakpoint(phargemobile) {
					display: none;
				}
			}
		}

		img {
			height: 100vh;
			width: auto;
			max-width: inherit;

			@include breakpoint(laptop) {
				height: auto;
				max-width: 100vw;
			}
			@include breakpoint(phargemobile) {
				height: inherit;
				max-width: 100%;
			}
		}
	}

	.init-slider {

		.slick-dots {

		    @include breakpoint(tablet) {
		        text-align: right;
		        padding: 0 110px 0 0;
		    }

		    @include breakpoint(phargemobile) {
		        text-align: right;
		        padding: 0 30px 0 0;
		    }
		}
	}

	@include breakpoint(tablet) {
		.wrap {
			padding: 40px 110px 0;
		}
	}
}

.legals {
	width: 100%;
	margin: 40px 0;

	@include breakpoint(tablet) {
		min-height: 50vh;
	}
	
	p {
		margin: 0 10px 20px 10px;
	}
}
