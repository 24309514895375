// FONTS
$font-base:			'Open Sans', sans-serif;
$font-title:		'adobe-garamond-pro',sans-serif;

// COLORS
$main-gray:			#949494;
$main-blue:			#002a4e;
$main-gold:			#b38b06;
$sec-gold:			#de9829;
$light-grey:		#fafafa;
$grey:				#666;
$white:				#fff;

// BREAKPOINTS

@mixin breakpoint($point) {
	@if $point == desktop {
		@media (min-width: 1440px) { @content ; }
	}
	@else if $point == laptop {
		@media (min-width: 1200px) { @content ; }
	}
	@else if $point == tablet {
		@media (min-width: 1024px) { @content ; }
	}
	@else if $point == phablet {
		@media (min-width: 768px)  { @content ; }
	}
	@else if $point == largemobile {
		@media (min-width: 361px)  { @content ; }
	}
	@else if $point == phargemobile {
		@media (max-width: 768px) and (orientation: landscape) { @content ; }
	}
}