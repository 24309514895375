.hero {
	width: 100%;
	height: 100vh;
	overflow: hidden;

	.slides {
		position: relative;
		height: 100vh;

		.tagline {
			width: 90%;
			text-align: center;
			position: absolute;
			z-index: 10;
			right: 50%;
			bottom: 70px;
			transform: translateX(50%);

			@include breakpoint(tablet) {
				max-width: 450px;
				text-align: right;
				right: 110px;
				bottom: 150px;
				transform: inherit;
			}

			@include breakpoint(phargemobile) {
				width: 60%;
				text-align: right;
				right: 30px;
				transform: inherit;
			}
			
			h2 {
				font: 34px/34px $font-title;
				color: $white;
				padding-bottom: 24px;
				margin-bottom: 20px;
				position: relative;

				&:after {
					content: '';
					width: 120px;
					border-bottom: 1px $sec-gold solid;
					position: absolute;
					bottom: 0px;
					right: 50%;
					transform: translateX(50%);

					@include breakpoint(tablet) {
	 					right: 0;
	 					transform: inherit;
	 				}
	 				@include breakpoint(phargemobile) {
	 					right: 0;
	 					transform: inherit;
	 				}
				}
			}
			p {
				display: none;
				font-size: 14px;
				color: $white;
				text-rendering: optimizeLegibility;
				font-smooth: always;

				@include breakpoint(largemobile) {
					display: block;
				}
				@include breakpoint(phargemobile) {
					display: none;
				}
			}
		}

		img {
			height: 100vh;
			width: auto;
			max-width: inherit;

			@include breakpoint(laptop) {
				height: auto;
				max-width: 100vw;
			}
			@include breakpoint(phargemobile) {
				height: inherit;
				max-width: 100%;
			}
		}
	}

	.init-slider {

		.slick-dots {

		    @include breakpoint(tablet) {
		        text-align: right;
		        padding: 0 110px 0 0;
		    }

		    @include breakpoint(phargemobile) {
		        text-align: right;
		        padding: 0 30px 0 0;
		    }
		}
	}

	@include breakpoint(tablet) {
		.wrap {
			padding: 40px 110px 0;
		}
	}
}
