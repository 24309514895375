.footer {
	padding: 25px 0;
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-direction: column;
	position: relative;

	.contact & {
		border-top: 1px $main-gray solid;
	}

	&> * {		
		flex: 1;
		text-align: center;
	}

	&-col1 {
		height: 32px;
		display: flex;
		justify-content: center;
	}

	&-col2 {
		margin: 40px 0;
	}

	@include breakpoint(tablet) {
		flex-direction: row;

		&-col2 {
			margin: 0;
		}


	}

	a {
		color: $main-blue;
		font-size: 16px;
		line-height: 32px;
		font-family: $font-title;

		&:hover {
			text-decoration: underline;
		}
	}

	&-logo-penn {
		width: 120px;
		margin-right: 10px;
	}
	&-tel {
		width: 13px;
		margin-left: 10px;
		margin-right: 10px;
	}

	&-logo-vmcgc {
		width: 113px;
	}
}