html,
body { -webkit-font-smoothing: antialiased; }

html { box-sizing: border-box; }
*, *:before, *:after { box-sizing: inherit; }

body * {
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
	color: $main-gray;
	font: normal 16px/26px $font-base;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
	font-weight: normal;
}

img { max-width: 100%; }
p { margin: 0; }
a { text-decoration: none;}

.wrap {
	// max-width: 1400px;
	padding: 0 15px;
	margin: 0 auto;

	@media screen and (min-width: 1024px) {
		padding: 0 50px;
	}
}