.contact {
	width: 100%;

	@include breakpoint(tablet) {
		margin-bottom: 20px;
		min-height: 50vh;
	}
	
	&-form {
		padding-top: 20px;

		@include breakpoint(phablet) {
			padding-top: 40px;
		}

		.row {
			
			@include breakpoint(phablet) {
				display: flex;
			}

			.form-group {
				flex: 1;
				margin: 0 10px;
				position: relative;
				margin-bottom: 30px;
				text-rendering: optimizeLegibility;
				font-smooth: always;

				&.val-error {
					label {
						color: red;
					}
					input {
						border-bottom-color: red!important; 
					}
				}

				&.focus label[for], &.active label[for] {
				    top: 0;
				    font-size: 11px;
				}
				
				label[for] {
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					padding: 0 10px;
					font-family: $font-base;
					font-size: 16px;
					pointer-events: none;
					transition: all .2s ease;

				}
				input {
					width: 100%;
					height: 50px;
					padding: 0 10px;
					border: 1px solid $main-gray;
					border-width: 0 0 1px;
					background-color: transparent;
					border-radius: 0;
					
					&:focus {
						border-color: $main-gold;
						outline: none;

						&+ label { 
							font-size: 12px;
							top: -5px;
						}
					}

					&[type=radio] {
						width: auto;
						height: 16px;

						&+ label {
							margin-left: 10px;
							position: static;
							transform: none;
							line-height: 50px;
							font-size: 16px;
							top: 50%;
						}
					}

					&[name=notes] {

						@include breakpoint(tablet) {
							width: 80%
						}
					}
				}

				&-col {
					// width: 30%;
					display: flex;
					align-items: center;					

					&:last-child {
						padding-left: 16px;
						width: auto;
					}

					p {
						font-size: 16px;
						padding: 0 10px;
						text-align: left;

						@include breakpoint(phablet) {
							padding-left: 20px;
						}

						small {
							font-size: 12px;
							display: block;
						}
					}
				}

				&.row {
					display: flex;

					&.interest {
						flex-direction: column;

						@include breakpoint(phablet) {
							flex-direction: row;
							margin: 30px 0;
						}

						.form-group-col {
							width: 100%;

							@include breakpoint(phablet) {
								flex: 1 1 20%;
								width: inherit;
							}

							&:last-child {
								flex: 1 1 auto;
								flex-wrap: wrap;
								padding-left: 10px;
								flex-direction: column;

								@include breakpoint(phablet) {
									padding: 0;
									flex-direction: row;
									flex: 1 1 80%; 
								}

								span {
									display: flex;
									flex-direction: column;
									
									label {
										min-width: 100px;
										padding: 10px 30px 0 0;

										@include breakpoint(phablet) {
											padding: 0 30px 0 0;
										}

										input {
											margin-right: 10px;
										}
									}
								}
							}

							input {
								width: auto;
								height: auto;
							}
						}
					}
				}
			}

			&:last-child {

				.form-group:last-child {
					text-align: center;

					@include breakpoint(phablet) {
						text-align: right;
					}
				}
			}
		}
	}

	&-form-title {
		h2 {
			font: 34px $font-title;
			color: $main-gold;
			text-align: center;

			@include breakpoint(phablet) {
				text-align: left;
			}
		}

		margin: 0 10px 20px 10px;

		@include breakpoint(phablet) {
			margin-bottom: 40px;
		}
	}

	&-thank {
		display: flex;
		padding: 40px 0;
		
		@include breakpoint(tablet) {
			min-height: 50vh;
		}

		aside {
			margin: auto;
			text-align: center;

			h2 {
				font-family: $font-title;
				color: $main-gold;

				@include breakpoint(tablet) {
					font-size: 36px;
				}
			}
			p {	
				line-height: 50px;
				margin-bottom: 46px;
			}
		}
	}

	.is-hidden, .is-hidden.loader {
	    display: none;
	    opacity: 0;
	    visibility: hidden;
	}

	.loader {
	    min-height: 50vh;
    	display: flex;

    	svg {
    		margin: auto;
    	}
	}
}
